<template>
  <div v-if="sectionData">
    <Disclosure
      as="div"
      class="relative bg-indigo-600 pb-32 overflow-hidden"
      v-slot="{ open }"
    >
      <div
        :class="[
          open ? 'bottom-0' : 'inset-y-0',
          'absolute flex justify-center inset-x-0 left-1/2 transform -translate-x-1/2 w-full overflow-hidden lg:inset-y-0',
        ]"
        aria-hidden="true"
      >
        <div class="flex-grow bg-indigo-600 bg-opacity-75" />
        <svg
          class="flex-shrink-0"
          width="1750"
          height="308"
          viewBox="0 0 1750 308"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity=".1"
            d="M1465.84 308L16.816 0H1750v308h-284.16z"
            fill="#fff"
          />
          <path
            opacity=".05"
            d="M1733.19 0L284.161 308H0V0h1733.19z"
            fill="#fff"
          />
        </svg>
        <div class="flex-grow bg-sky-800 bg-opacity-75" />
      </div>
      <header class="relative py-10">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 class="text-3xl font-bold text-white">Settings</h1>
        </div>
      </header>
    </Disclosure>

    <main class="relative -mt-32">
      <div class="max-w-screen-xl mx-auto pb-6 px-4 sm:px-6 lg:pb-16 lg:px-8">
        <div class="bg-white rounded-lg shadow overflow-hidden">
          <div
            class="
              divide-y divide-gray-200
              lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x
            "
          >
            <aside class="py-6 lg:col-span-3">
              <nav class="space-y-1">
                <a
                  v-for="item in subNavigation"
                  :key="item.name"
                  :href="item.href"
                  :class="[
                    item.current
                      ? 'bg-teal-50 border-teal-500 text-teal-700 hover:bg-teal-50 hover:text-teal-700'
                      : 'border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                    'group border-l-4 px-3 py-2 flex items-center text-sm font-medium',
                  ]"
                  :aria-current="item.current ? 'page' : undefined"
                >
                  <component
                    :is="item.icon"
                    :class="[
                      item.current
                        ? 'text-teal-500 group-hover:text-teal-500'
                        : 'text-gray-400 group-hover:text-gray-500',
                      'flex-shrink-0 -ml-1 mr-3 h-6 w-6',
                    ]"
                    aria-hidden="true"
                  />
                  <span class="truncate">
                    {{ item.name }}
                  </span>
                </a>
              </nav>
            </aside>

            <form
              class="divide-y divide-gray-200 lg:col-span-9"
              action="#"
              method="POST"
            >
            
                <!-- Plan -->
                <section aria-labelledby="plan-heading">
                  <form action="#" method="POST">
                    <div class="sm:overflow-hidden">
                      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
                        <div class="bg-white">
                          <div class="mx-auto">
                            <!-- xs to lg -->
                            <div class="max-w-2xl mx-auto space-y-16 lg:hidden">
                              <section v-for="(tier, tierIdx) in tiers" :key="tier.name">
                                <div class="px-4 mb-8">
                                  <h2 class="text-lg leading-6 font-medium text-gray-900">{{ tier.name }}</h2>
                                  <div class="text-sm text-gray-500">{{ tier.description }}</div>
                                  <p class="mt-4">
                                    <span class="text-4xl font-extrabold text-gray-900">${{ tier.priceMonthly }}</span>
                                    {{ ' ' }}
                                    <span class="text-base font-medium text-gray-500">/{{ tier.period }}</span>
                                  </p>
                                  <a v-if="tier.showbutton" v-on:click="selectPayment(tier.value)" :href="tier.href" class="mt-6 block border border-gray-800 rounded-md bg-gray-800 w-full py-2 text-sm font-semibold text-white text-center hover:bg-gray-900">Buy {{ tier.name }}</a>
                                </div>

                                <table v-for="section in sections" :key="section.name" class="w-full">
                                  <caption class="bg-gray-50 border-t border-gray-200 py-3 px-4 text-sm font-medium text-gray-900 text-left">
                                    {{
                                      section.name
                                    }}
                                  </caption>
                                  <thead>
                                    <tr>
                                      <th class="sr-only" scope="col">Feature</th>
                                      <th class="sr-only" scope="col">Included</th>
                                    </tr>
                                  </thead>
                                  <tbody class="divide-y divide-gray-200">
                                    <tr v-for="feature in section.features" :key="feature.name" class="border-t border-gray-200">
                                      <th class="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">{{ feature.name }}</th>
                                      <td class="py-5 pr-4">
                                        <span v-if="typeof feature.tiers[tier.name] === 'string'" class="block text-sm text-gray-700 text-right">{{ feature.tiers[tier.name] }}</span>
                                        <template v-else>
                                          <CheckIcon v-if="feature.tiers[tier.name] === true" class="ml-auto h-5 w-5 text-green-500" aria-hidden="true" />
                                          <MinusIcon v-else class="ml-auto h-5 w-5 text-gray-400" aria-hidden="true" />
                                          <span class="sr-only">{{ feature.tiers[tier.name] === true ? 'Yes' : 'No' }}</span>
                                        </template>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>

                                <div :class="[tierIdx < tiers.length - 1 ? 'py-5 border-b' : 'pt-5', 'border-t border-gray-200 px-4']">
                                  <a :href="tier.href" class="block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900">Buy {{ tier.name }}</a>
                                </div>
                              </section>
                            </div>

                            <!-- lg+ -->
                            <div class="hidden lg:block">
                              <table class="w-full h-px table-fixed">
                                <caption class="sr-only">
                                  Pricing plan comparison
                                </caption>
                                <thead>
                                  <tr>
                                    <th class="relative w-1/4 py-2 px-6 text-lg align-middle leading-6 font-medium text-gray-900 text-left">
                                      Plan
                                    </th>
                                    <th v-for="tier in tiers" :key="tier.name" class="relative w-1/4 py-2 px-6 text-lg align-middle leading-6 font-medium text-gray-900 text-left" scope="col">
                                    <div v-if="tier.isSelected" class="border-l-2 border-t-2 border-r-2 border-indigo-600 h-full w-full absolute top-0 left-0"></div>
                                    <div v-if="tier.isPending" class="bg-blue-50 h-full w-full absolute top-0 left-0 z-10"></div>

                                    <span class="relative z-20">{{ tier.name }}</span> 
                                  
                                  <div class="text-sm text-gray-500 inline-block relative z-20">{{ tier.description }}</div></th>
                                  </tr>
                                </thead>
                                <tbody class="border-t border-gray-200 divide-y divide-gray-200">
                                  <tr>
                                    <td></td>
                                    <td v-for="tier in tiers" :key="tier.name" class="h-full py-4 px-6 align-middle relative"
                                    >
                                    <div v-if="tier.isSelected" class="border-l-2 border-r-2 border-indigo-600 h-full w-full absolute top-0 left-0"></div>
                                    <div v-if="tier.isPending" class="bg-blue-50 z-10 h-full w-full absolute top-0 left-0"></div>
                                      <div class="relative h-full z-20 table">
                                        
                                        <p>
                                          <span class="text-4xl font-extrabold text-gray-900">${{ tier.priceMonthly }}</span>
                                          <span class="text-base font-medium text-gray-500">/mo</span>
                                        </p>
                                      </div>
                                    </td>
                                  </tr>
                                  <template v-for="section in sections" :key="section.name">
                                    <tr>
                                      <th class="bg-gray-50 py-3 pl-6 text-sm font-medium text-gray-900 text-left relaitve" scope="colgroup">{{ section.name }}</th>
                                      <th :class="[tier.isPending ? 'bg-blue-50' : 'bg-gray-50']" class="bg-gray-50 py-3 pl-6 text-sm font-medium text-gray-900 text-left relative" v-for="tier in tiers" :key="tier.name">
                                        <div v-if="tier.isSelected" class="border-l-2 border-r-2 border-indigo-600 h-full w-full absolute top-0 left-0">

                                          </div>
                                      </th>
                                    </tr>
                                    <tr v-for="feature in section.features" :key="feature.name">
                                      <th class="py-5 px-6 text-sm font-normal text-gray-500 text-left" scope="row">{{ feature.name }}</th>
                                      <td v-for="tier in tiers" :key="tier.name" :class="[tier.isPending ? 'bg-blue-50' : '']" class="py-5 px-6 relative">
                                        <div v-if="tier.isSelected" class="border-l-2 border-r-2 border-indigo-600 h-full w-full absolute top-0 left-0"></div>
                                        <span v-if="typeof feature.tiers[tier.name] === 'string'" class="block text-sm text-gray-700">{{ feature.tiers[tier.name] }}</span>
                                        <template v-else>
                                          <CheckIcon v-if="feature.tiers[tier.name] === true" class="h-5 w-5 text-green-500" aria-hidden="true" />
                                          <MinusIcon v-else class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                          <span class="sr-only">{{ feature.tiers[tier.name] === true ? 'Included' : 'Not included' }} in {{ tier.name }}</span>
                                        </template>
                                      </td>
                                    </tr>
                                  </template>
                                </tbody>
                                <tfoot>
                                  <tr class="border-t border-gray-200">
                                    <th class="sr-only" scope="row">Choose your plan</th>
                                    <td :class="[tier.isPending ? 'bg-blue-50' : '']"  v-for="tier in tiers" :key="tier.name" class="py-4 px-6 relative">
                                        <a v-if="!tier.isSelected && !tier.isPending" v-on:click="selectPayment(tier)" :href="tier.href" class="mt-2 relative z-10  flex-grow block w-full bg-indigo-600 border border-indigo-600 rounded-md 5 py-2 text-sm font-semibold text-white text-center hover:bg-indigo-800">Select Plan</a>
                                        <a v-if="tier.isPending" v-on:click="selectPayment(tier)" :href="tier.href" class="mt-2 relative z-10  flex-grow block w-full bg-blue-600 border border-blue-600 rounded-md 5 py-2 text-sm font-semibold text-white text-center hover:bg-blue-800">Selected Plan</a>
                                        <a v-if="tier.isSelected" :href="tier.href" class="mt-2 relative z-10 flex-grow block w-full bg-gray-400 border border-gray-400 rounded-md 5 py-2 text-sm font-semibold text-gray-800 text-center hover:bg-gray-300 cursor-not-allowed">Current Plan</a>
                                        <div v-if="tier.isSelected" class="border-l-2 border-r-2  border-b-2 border-indigo-600 h-full w-full absolute top-0 left-0">

                                          </div>
                                    </td>
                                  </tr>
                                </tfoot>
                              </table>
                            </div>
                          </div>
                        </div>
                        <RadioGroup v-model="selectedPlan">
                          <RadioGroupLabel class="sr-only">
                            Pricing plans
                          </RadioGroupLabel>
                          <div class="relative bg-white rounded-md -space-y-px">
                            <RadioGroupOption
                              as="template"
                              v-for="(plan, planIdx) in plans"
                              :key="plan.name"
                              :value="plan"
                              v-slot="{ checked, active }"
                            >
                              <div
                                :class="[
                                  planIdx === 0
                                    ? 'rounded-tl-md rounded-tr-md'
                                    : '',
                                  planIdx === plans.length - 1
                                    ? 'rounded-bl-md rounded-br-md'
                                    : '',
                                  checked
                                    ? 'bg-orange-50 border-orange-200 z-10'
                                    : 'border-gray-200',
                                  'relative border p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 md:grid md:grid-cols-3 focus:outline-none',
                                ]"
                              >
                                <div class="flex items-center text-sm">
                                  <span
                                    :class="[
                                      checked
                                        ? 'bg-orange-500 border-transparent'
                                        : 'bg-white border-gray-300',
                                      active
                                        ? 'ring-2 ring-offset-2 ring-gray-900'
                                        : '',
                                      'h-4 w-4 rounded-full border flex items-center justify-center',
                                    ]"
                                    aria-hidden="true"
                                  >
                                    <span
                                      class="rounded-full bg-white w-1.5 h-1.5"
                                    />
                                  </span>
                                  <RadioGroupLabel
                                    as="span"
                                    class="ml-3 font-medium text-gray-900"
                                    >{{ plan.name }}</RadioGroupLabel
                                  >
                                </div>
                                <RadioGroupDescription
                                  class="
                                    ml-6
                                    pl-1
                                    text-sm
                                    md:ml-0 md:pl-0 md:text-center
                                  "
                                >
                                  <span
                                    :class="[
                                      checked
                                        ? 'text-orange-900'
                                        : 'text-gray-900',
                                      'font-medium',
                                    ]"
                                    >${{ plan.priceMonthly }} / mo</span
                                  >
                                  {{ " " }}
                                  <span
                                    :class="
                                      checked
                                        ? 'text-orange-700'
                                        : 'text-gray-500'
                                    "
                                    >(${{ plan.priceYearly }} / yr)</span
                                  >
                                </RadioGroupDescription>
                                <RadioGroupDescription
                                  :class="[
                                    checked
                                      ? 'text-orange-700'
                                      : 'text-gray-500',
                                    'ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-right',
                                  ]"
                                  >{{ plan.limit }}</RadioGroupDescription
                                >
                              </div>
                            </RadioGroupOption>
                          </div>
                        </RadioGroup>

                        <SwitchGroup as="div" class="flex items-center hidden">
                          <SwitchLabel as="span" class="mr-4 text-sm">
                            <span :class="[
                                annualBillingEnabled
                                  ? ' text-gray-500'
                                  : ' text-gray-900 font-medium',
                              ]">Free
                            </span>
                            <span class="text-sm text-gray-500 "
                              >(Limited features)</span
                            >
                          </SwitchLabel>
                          <Switch
                            v-model="annualBillingEnabled"
                            :class="[
                              annualBillingEnabled
                                ? 'bg-indigo-600'
                                : 'bg-gray-200',
                              'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 transition-colors ease-in-out duration-200',
                            ]"
                          >
                            <span
                              aria-hidden="true"
                              :class="[
                                annualBillingEnabled
                                  ? 'translate-x-5'
                                  : 'translate-x-0',
                                'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                              ]"
                            />
                          </Switch>
                          <SwitchLabel as="span" class="ml-3">
                            <span class="text-sm"
                            :class="[
                                annualBillingEnabled
                                  ? ' text-gray-900 font-medium'
                                  : ' text-gray-500',
                              ]"
                              >Professional
                            </span>
                            <span class="text-sm text-gray-500"
                              >()</span
                            >
                          </SwitchLabel>
                        </SwitchGroup>
                      </div>
                    </div>
                  </form>
                </section>
              <!-- Payment details -->
              <div v-if="userData" class="space-y-6 sm:px-6 lg:px-0 lg:col-span-9" id="payment">
                <section aria-labelledby="payment-details-heading">
                  <form action="#" method="POST">
                    <div class="sm:overflow-hidden">
                      <div class="bg-white py-6 px-4 sm:p-6">
                        <div>
                          <h2
                            id="payment-details-heading"
                            class="text-lg leading-6 font-medium text-gray-900"
                          >
                            {{ sectionData[0].topText }}
                          </h2>
                          <p class="mt-1 text-sm text-gray-500">
                            {{ sectionData[0].middleText }}
                          </p>
                        </div>

                        <div class="mt-6 grid grid-cols-4 gap-6">
                          <div class="col-span-4 sm:col-span-2">
                            <label
                              for="first-name"
                              class="block text-sm font-medium text-gray-700"
                              >First name</label
                            >
                            <input
                              @input="updateField('firstName', $event.target.value)"
                              type="text"
                              name="first-name"
                              id="first-name"
                              autocomplete="cc-given-name"
                              class="
                                mt-1
                                block
                                w-full
                                border border-gray-300
                                rounded-md
                                shadow-sm
                                py-2
                                px-3
                                focus:outline-none
                                focus:ring-gray-900
                                focus:border-gray-900
                                sm:text-sm
                              "
                              v-bind:class="[
                              !billingInfo.firstName
                                ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
                                : '',
                              ]"
                            />
                          </div>

                          <div class="col-span-4 sm:col-span-2">
                            <label
                              for="last-name"
                              class="block text-sm font-medium text-gray-700"
                              >Last name</label
                            >
                            <input
                              @input="updateField('lastName', $event.target.value)"
                              type="text"
                              name="last-name"
                              id="last-name"
                              autocomplete="cc-family-name"
                              class="
                                mt-1
                                block
                                w-full
                                border border-gray-300
                                rounded-md
                                shadow-sm
                                py-2
                                px-3
                                focus:outline-none
                                focus:ring-gray-900
                                focus:border-gray-900
                                sm:text-sm
                              "
                              v-bind:class="[
                              !billingInfo.lastName
                                ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
                                : '',
                              ]"
                            />
                          </div>
          <div class="col-span-4 sm:col-span-4">
                            <label
                              for="email-address"
                              class="block text-sm font-medium text-gray-700"
                              >Billing Email address</label
                            >
                            <input
                              @input="updateField('billingEmail', $event.target.value)"
                              type="text"
                              name="email-address"
                              id="email-address"
                              autocomplete="email"
                              class="
                                mt-1
                                block
                                w-full
                                border border-gray-300
                                rounded-md
                                shadow-sm
                                py-2
                                px-3
                                focus:outline-none
                                focus:ring-gray-900
                                focus:border-gray-900
                                sm:text-sm
                              "
                              v-bind:class="[
                              !billingInfo.billingEmail
                                ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
                                : '',
                              ]"
                            />
                          </div>
                          <div class="col-span-4 sm:col-span-2">
                            <label
                              for="country"
                              class="block text-sm font-medium text-gray-700"
                              >Country / Region</label
                            >
                            <select
                              @change="updateField('country', $event.target.value)"
                              id="country"
                              name="country"
                              autocomplete="country"
                              class="
                                mt-1
                                block
                                w-full
                                bg-white
                                border border-gray-300
                                rounded-md
                                shadow-sm
                                py-2
                                px-3
                                focus:outline-none
                                focus:ring-gray-900
                                focus:border-gray-900
                                sm:text-sm
                              "
                              v-bind:class="[
                              !isValidCountry
                                ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
                                : '',
                              ]"
                            >
                              <option selected disabled>-- Please Select --</option>
                              <option>Canada</option>
                              <option>United States</option>
                              <option>Mexico</option>
                            </select>
                          </div>

                          <div class="col-span-4 sm:col-span-2">
                            <label
                              for="postal-code"
                              class="block text-sm font-medium text-gray-700"
                              >ZIP / Postal</label
                            >
                            <input
                              @input="updateField('zipPostal', $event.target.value)"
                              type="text"
                              name="postal-code"
                              id="postal-code"
                              autocomplete="postal-code"
                              class="
                                mt-1
                                block
                                w-full
                                border border-gray-300
                                rounded-md
                                shadow-sm
                                py-2
                                px-3
                                focus:outline-none
                                focus:ring-gray-900
                                focus:border-gray-900
                                sm:text-sm
                              "
                              v-bind:class="[
                              !billingInfo.zipPostal
                                ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
                                : '',
                              ]"
                            />
                          </div>
                          <div class="col-span-4 sm:col-span-4">
                            <label
                              for="card-number"
                              class="block text-sm font-medium text-gray-700"
                              >Card Number</label
                            >
                            <input
                              type="text"
                              name="card-number"
                              id="card-number"
                              autocomplete=""
                              class="
                                mt-1
                                block
                                w-full
                                border border-gray-300
                                rounded-md
                                shadow-sm
                                py-2
                                px-3
                                focus:outline-none
                                focus:ring-gray-900
                                focus:border-gray-900
                                sm:text-sm
                              "
                              v-bind:class="[
                              !billingInfo.billingEmail
                                ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
                                : '',
                              ]"
                            />
                          </div>

                          <div class="col-span-4 sm:col-span-2">
                            <label
                              for="expiration-date"
                              class="block text-sm font-medium text-gray-700"
                              >Expration date</label
                            >
                            <input
                              @input="updateField('expDate', $event.target.value)"
                              type="text"
                              name="expiration-date"
                              id="expiration-date"
                              autocomplete="cc-exp"
                              class="
                                mt-1
                                block
                                w-full
                                border border-gray-300
                                rounded-md
                                shadow-sm
                                py-2
                                px-3
                                focus:outline-none
                                focus:ring-gray-900
                                focus:border-gray-900
                                sm:text-sm
                              "
                              v-bind:class="[
                              !billingInfo.expDate
                                ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
                                : '',
                              ]"
                              placeholder="MM / YY"
                            />
                          </div>

                          <div class="col-span-4 sm:col-span-2">
                            <label
                              for="security-code"
                              class="
                                flex
                                items-center
                                text-sm
                                font-medium
                                text-gray-700
                              "
                            >
                              <span>Security code</span>
                              <QuestionMarkCircleIcon
                                class="ml-1 flex-shrink-0 h-5 w-5 text-gray-300"
                                aria-hidden="true"
                              />
                            </label>
                            <input
                              @input="updateField('secCode', $event.target.value)"
                              type="text"
                              name="security-code"
                              id="security-code"
                              autocomplete="cc-csc"
                              class="
                                mt-1
                                block
                                w-full
                                border border-gray-300
                                rounded-md
                                shadow-sm
                                py-2
                                px-3
                                focus:outline-none
                                focus:ring-gray-900
                                focus:border-gray-900
                                sm:text-sm
                              "
                              v-bind:class="[
                              !billingInfo.secCode
                                ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
                                : '',
                              ]"
                            />
                          </div>

                          
                        </div>
                      </div>
                      <div class="px-4 py-3 bg-gray-50 sm:px-6">
                        <div class="">
                          <label
                              for="membership"
                              class="block text-sm font-medium mb-2 text-gray-700"
                              >Membership type</label
                            >
                          <select
                              @change="updateField('membershipType', $event.target.value)"
                              id="membership"
                              name="membership"
                              autocomplete="membership"
                              class="
                              col
                                mt-1
                                block
                                w-full
                                bg-white
                                border border-gray-300
                                rounded-md
                                shadow-sm
                                py-2
                                px-3
                                focus:outline-none
                                focus:ring-gray-900
                                focus:border-gray-900
                                sm:text-sm
                              "
                              v-bind:class="[
                              true
                                ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
                                : '',
                              ]"
                            >
                              <option selected disabled>-- Please Select --</option>
                              <option value="1">Annual Membership</option>
                              <option value="2">Monthly Membership</option>
                            </select>
                            
                        
                        <button
                          type="submit"
                          class="
                          w-full
                          text-center
                          mt-3
                            border border-transparent
                            rounded-md
                            shadow-sm
                            py-2
                            px-4
                            inline-flex
                            justify-center
                            text-sm
                            font-medium
                            text-white
                            focus:outline-none
                            focus:ring-2
                            focus:ring-offset-2
                            focus:ring-indigo-400
                          "
                          :disabled="!hasEssentialFields"
                          v-bind:class="[
                          true
                            ? 'bg-indigo-600 hover:bg-blue-700'
                            : 'bg-indigo-400 cursor-not-allowed',
                          ]"
                        >
                          Purchase Plan
                        </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </section>


                <!-- Billing history -->
                <section aria-labelledby="billing-history-heading">
                  <div class="bg-white pt-6 sm:overflow-hidden">
                    <div class="px-4 sm:px-6">
                      <h2
                        id="billing-history-heading"
                        class="text-lg leading-6 font-medium text-gray-900"
                      >
                        Billing history
                      </h2>
                    </div>
                    <div class="mt-6 flex flex-col">
                      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div
                          class="
                            py-2
                            align-middle
                            inline-block
                            min-w-full
                            sm:px-6
                            lg:px-8
                          "
                        >
                          <div class="overflow-hidden border-t border-gray-200">
                            <table class="min-w-full divide-y divide-gray-200">
                              <thead class="bg-gray-50">
                                <tr>
                                  <th
                                    scope="col"
                                    class="
                                      px-6
                                      py-3
                                      text-left text-xs
                                      font-medium
                                      text-gray-500
                                      uppercase
                                      tracking-wider
                                    "
                                  >
                                    Date
                                  </th>
                                  <th
                                    scope="col"
                                    class="
                                      px-6
                                      py-3
                                      text-left text-xs
                                      font-medium
                                      text-gray-500
                                      uppercase
                                      tracking-wider
                                    "
                                  >
                                    Description
                                  </th>
                                  <th
                                    scope="col"
                                    class="
                                      px-6
                                      py-3
                                      text-left text-xs
                                      font-medium
                                      text-gray-500
                                      uppercase
                                      tracking-wider
                                    "
                                  >
                                    Amount
                                  </th>
                                  <!--
                              `relative` is added here due to a weird bug in Safari that causes `sr-only` headings to introduce overflow on the body on mobile.
                            -->
                                  <th
                                    scope="col"
                                    class="
                                      relative
                                      px-6
                                      py-3
                                      text-left text-xs
                                      font-medium
                                      text-gray-500
                                      uppercase
                                      tracking-wider
                                    "
                                  >
                                    <span class="sr-only">View receipt</span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody class="bg-white divide-y divide-gray-200">
                                <tr
                                  v-for="payment in payments"
                                  :key="payment.id"
                                >
                                  <td
                                    class="
                                      px-6
                                      py-4
                                      whitespace-nowrap
                                      text-sm
                                      font-medium
                                      text-gray-900
                                    "
                                  >
                                    <time :datetime="payment.datetime">{{
                                      payment.date
                                    }}</time>
                                  </td>
                                  <td
                                    class="
                                      px-6
                                      py-4
                                      whitespace-nowrap
                                      text-sm text-gray-500
                                    "
                                  >
                                    {{ payment.description }}
                                  </td>
                                  <td
                                    class="
                                      px-6
                                      py-4
                                      whitespace-nowrap
                                      text-sm text-gray-500
                                    "
                                  >
                                    {{ payment.amount }}
                                  </td>
                                  <td
                                    class="
                                      px-6
                                      py-4
                                      whitespace-nowrap
                                      text-right text-sm
                                      font-medium
                                    "
                                  >
                                    <a
                                      :href="payment.href"
                                      class="
                                        text-orange-600
                                        hover:text-orange-900
                                      "
                                      >View receipt</a
                                    >
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { ref } from "vue";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Switch,
  SwitchDescription,
  SwitchGroup,
  SwitchLabel,
} from "@headlessui/vue";
import { SearchIcon, CheckIcon, MinusIcon } from "@heroicons/vue/solid";
import {
  BellIcon,
  CogIcon,
  CreditCardIcon,
  KeyIcon,
  MenuIcon,
  UserCircleIcon,
  VideoCameraIcon,
  PhotographIcon,
  XIcon,
} from "@heroicons/vue/outline";
import { OcContentService } from "../services/ocContentService";
import { CookieService } from '../services/cookieService';
import { NopService } from '../services/nopService';


const tiers = [
  { 
    name: 'Free', 
    href: '#payment', 
    priceMonthly: 0, 
    period: 'mo', 
    description: '', 
    isSelected: true,
    isPending: false,
    value: 0,
  },
  {
    name: 'Monthly',
    href: '#payment',
    priceMonthly: 9.99,
    period: 'mo',
    description: '',
    isSelected: false,
    isPending: false,
    value: 2,
  },
  {
    name: 'Annual',
    href: '#payment',
    priceMonthly: 99.99,
    period: 'yr',
    description: ' (Save 17%)',
    isSelected: false,
    isPending: false,
    value: 1,
  },
]
const sections = [
  {
    name: 'Features',
    features: [
      { name: 'Most Leads', tiers: { Free: false, Monthly: true, Annual: true } },
      { name: 'High Visibility', tiers: { Free: false, Monthly: true, Annual: true } },
      { name: 'Photos', tiers: { Free: '4 Photos max', Monthly: 'Up to 10 photos', Annual: 'Up to 10 photos' } },
      { name: 'Videos', tiers: { Free: '4 Videos max', Monthly: 'Up to 10 videos', Annual: 'Up to 10 videos' } },
    ],
  },
]

const user = {
  name: "John Cooper",
  handle: "johncooper",
  email: "johncooper@example.com",
  imageUrl:
    "https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
};
const navigation = [
  { name: "Dashboard", href: "#", current: true },
  { name: "Jobs", href: "#", current: false },
  { name: "Applicants", href: "#", current: false },
  { name: "Company", href: "#", current: false },
];
const subNavigation = [
  { name: "Profile", href: "/Dashboard", icon: UserCircleIcon, current: false },
  { name: "Media", href: "/Media", icon: PhotographIcon, current: false },
  { name: "Videos", href: "/Videos", icon: VideoCameraIcon, current: false },
  { name: "Password", href: "/Password", icon: KeyIcon, current: false },
  { name: "Membership", href: "/Billing", icon: CreditCardIcon, current: true },
];
const userNavigation = [
  { name: "Your Profile", href: "#" },
  { name: "Settings", href: "#" },
  { name: "Sign out", href: "#" },
];
const plans = [
  {
    name: "Startup",
    priceMonthly: 29,
    priceYearly: 290,
    limit: "Up to 5 active job postings",
  },
  {
    name: "Business",
    priceMonthly: 99,
    priceYearly: 990,
    limit: "Up to 25 active job postings",
  },
  {
    name: "Enterprise",
    priceMonthly: 249,
    priceYearly: 2490,
    limit: "Unlimited active job postings",
  },
];
const payments = [
  {
    id: 1,
    date: "1/1/2022",
    datetime: "2022-01-01",
    description: "Annual Billing",
    amount: "CA$99.99",
    href: "#",
  },
  // More payments...
];

export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Switch,
    SwitchDescription,
    SwitchGroup,
    SwitchLabel,
    BellIcon,
    MenuIcon,
    SearchIcon,
    XIcon,
    CheckIcon,
    MinusIcon,
  },
  setup() {
    const availableToHire = ref(true);
    const privateAccount = ref(false);
    const allowCommenting = ref(true);
    const allowMentions = ref(true);

    const selectedPlan = ref(plans[1]);
    const annualBillingEnabled = ref(true);

    return {
      user,
      navigation,
      subNavigation,
      userNavigation,
      availableToHire,
      privateAccount,
      allowCommenting,
      allowMentions,
      payments,
      selectedPlan,
      annualBillingEnabled,
      tiers,
      sections,
    };
  },
  data() {
    return {
      sectionData: null,
      userData: null,
      isLoading: false,
      hasEssentialFields: false,
      isValidCountry: false,
      isValidMembership: false,
      billingInfo: {
        firstName: "",
        lastName: "",
        billingEmail: "",
        expDate: "",
        secCode: "",
        country: "",
        zipPostal: "",
        membershipType: "",
      }
    };
  },
  created() {
    this.isLoading = true;
    this.fetchOcData();
    this.fetchUserData()
    this.isLoading = false;
  },
  methods: {
    async fetchOcData() {
      this.sectionData = await OcContentService.fetchPageData(
        "Settings - Billing",
        "KrickersSectionContent"
      );
    },
    async fetchUserData() {
      let id = CookieService.getLoginCookie()
      if (id) {
        this.userData = await NopService.GetCustomerDetailsById(id);
        this.privateAccount = this.userData.IsAccountPrivate;
      }
    },
    updateField(field, value) {
      this.billingInfo[field] = value;
      this.checkEssentialFields();
    },
    checkEssentialFields() {
      if (this.billingInfo.firstName && this.billingInfo.lastName && 
          this.billingInfo.expDate && this.billingInfo.secCode && 
          this.billingInfo.billingEmail && this.billingInfo.country && 
          this.billingInfo.zipPostal && this.billingInfo.membershipType) {
        this.hasEssentialFields = true;
      }
      else {
        this.hasEssentialFields = false;
      }
    },
    selectPayment(tier){
      document.getElementById("membership").value = tier.value;
      tiers.forEach((item) => {
          item.isPending = false;
      });
      tier.isPending = true;
    }
  },
};
</script>
